import _ from 'lodash';
import styled from 'styled-components';
import { SectionHead } from 'src/components/cards';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const WidgetTags = ({ data = [], title = 'Trends for you', eventDataLayer, style = 'default', className = 'special', isVideo = false }) => {
  if (_.isEmpty(data)) return null;
  return (
    <>
      {style === 'special' && (
        <WidgetWrapper id='widget-tags' className='widget-tags'>
          {/* <SectionHead data={title} /> */}
          <div className={`scroll-tag ${className}`}>
            <ul>
              <li>{title}</li>
              {data.map((item, index) => (
                <li key={index}>
                  <a
                    href={`${isVideo ? `/video/tags/${item?.name}` : `/tags/${item?.name}`}`}
                    title={item?.name}
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          ...eventDataLayer?.data,
                          title: item?.name,
                          heading: title,
                          index: index + 1
                        }
                      })
                    }
                  >
                    #{item?.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </WidgetWrapper>
      )}

      {style === 'default' && (
        <WidgetWrapper id='widget-tags' className='widget-tags'>
          <SectionHead data={title} />
          <div className='scroll-tag'>
            <ul>
              {data.map((item, index) => (
                <li key={index}>
                  <a
                    href={`${isVideo ? `/video/tags/${item?.name}` : `/tags/${item?.name}`}`}
                    title={item?.name}
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          ...eventDataLayer?.data,
                          title: item?.name,
                          heading: title,
                          index: index + 1
                        }
                      })
                    }
                  >
                    #{item?.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </WidgetWrapper>
      )}
    </>
  );
};

const WidgetWrapper = styled.div`
  .scroll-tag {
    height: 168px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(247, 247, 247, 0.9);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: rgba(255, 230, 0, 0.7);
    }
  }
  ul {
    list-style-type: none;
    /* margin-top: 8px; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 0;
    li {
      display: flex;
      height: 32px;
      justify-content: center;
      align-items: center;
      font-size: 16 px;
      font-weight: 500;
      margin: 5px 8px 5px 0;
      background-color: #ffe000;
      padding: 0 16px;
      /* box-shadow: 2px 2px 1px #000; */

      transition: all 0.5s;
      &:hover {
        background-color: rgba(0, 0, 0);
        /* box-shadow: 2px 2px 1px rgba(255, 230, 0, 0.8); */
        /* -webkit-box-shadow: 1px 1px 1px 0px rgba(255, 230, 0, 0.8);
        -moz-box-shadow: 1px 1px 1px 0px rgba(255, 230, 0, 0.8); */
        a {
          color: #ffe000;
        }
      }
    }
  }

  .scroll-tag.special {
    background: #000;
    padding: 10px;
    ul {
      li {
        border: 1px solid #ffe000;
        &:nth-child(1) {
          padding: 0;
          color: #ffe000;
          background: transparent;
          text-transform: uppercase;
          border: 1px solid transparent;
        }
      }
    }
  }
`;
export default WidgetTags;
